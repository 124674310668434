@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "franklin";
  src: url("../public/fonts/FranklinAve.-Oblique.otf") format("opentype");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bricolage";
  src: url("../public/fonts/BricolageGrotesque-VariableFont_opsz\,wdth\,wght.ttf")
    format("TrueType");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "architect";
  src: url("../public//fonts/ArchitectsDaughter-Regular.ttf") format("TrueType");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Span";
  src: url("../public/fonts/Span-Compressed-Bold.otf") format("opentype");

  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SpanLight";
  src: url("../public/fonts/Span\ Compressed\ Light.otf") format("opentype");

  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Napzer";
  src: url("../public/fonts/Napzer-Regular.otf") format("opentype");

  font-weight: regular;
  font-style: normal;
}

body, html {
  overflow-x: hidden; /* Prevent horizontal scroll */
}

